<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">{{descripcion}}</p>
                        </div>  
                      </div>
                    </div>
                  </div>    



    <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">

                <h6 class="card-title">Reporte general mensual</h6>
                          <p class="card-description">En este reporte podra visualizar dia por dia los consumos de su nomina ( Recomendamos no buscar en rangos mayores a 7 dias )</p>

                          <table>
                            <td>Fecha desde</td>
                            <td><input type="date" id="desde"></td>
                            <td>Fecha hasta</td>
                            <td><input type="date" id="hasta"></td>
                            <td><button type="button" class="btn btn-primary btn-xs" id="buscar">Buscar</button></td>
                          </table>


                          </div>  
                      </div>
                    </div>
                  </div>    

    <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                          <div class="card-body">
                            



                                        <div class="table-responsive">
                                          
                                                    <table id="comensales"  class="display table" width="100%">
                                                    <thead>
                                                    <tr>

                                                    <th>Fecha</th>
                                                    <th class="retiro">Ficho en tablet</th>
                                                    <th>Momento</th>
                                                    <th>Identificador</th>
                                                    <th>Nombre</th>
                                                    <th>Sucursal</th>
                                                    <th>Plato</th>
                                                    <th class="email">Condicion</th>
                                                    <th>Status</th>

                                                    </tr>
                                                    </thead>


                                                    <tfoot>
                                                    <tr>
                                                    <th>Fecha</th>
                                                    <th class="retiro">Ficho en tablet</th>
                                                    <th>Momento</th>
                                                    <th>Identificador</th>
                                                    <th>Nombre</th>
                                                    <th>Sucursal</th>
                                                    <th>Plato</th>
                                                    <th class="email">Condicion</th>
                                                    <th>Status</th>
                                                    </tr>
                                                    </tfoot>



                                                    </table>

                                                    <center id="mensaje"></center>
                                                    <div id="consumos_tabla"></div>



                                        </div>



                        </div>
     
                    </div>
                </div>

            </div>
        </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
let empresa = sessionStorage.getItem('empresa');
const userData = JSON.parse(sessionStorage.getItem('userData'));

 

var url = "";

export default {
  name: 'Reservas',
   data() {
        return {
      breadcrumbA: 'Reservas',
      breadcrumbB: 'Portal de reservas mensuales',
      descripcion:'En esta secciòn podra visualizar todas las reservas mensuales de los comensales de su empresa',
      comensales:[]
   }
    }, 
    async mounted() {

      console.log(empresa)


            $( "#buscar").click(function() {
               
                $("#mensaje").html('<center>Aguarde un instante estamos calculando los consumos</center>');

                $('#comensales').DataTable().destroy();


                 var desd = $("#desde").val().split("-");
                 var hast = $("#hasta").val().split("-");
                 console.log(empresa)

                 var desde = desd[2]+'-'+desd[1]+'-'+desd[0];
                 var hasta =  hast[2]+'-'+hast[1]+'-'+hast[0];

                     var url_mensual='https://comedorfs-app.firebaseio.com/carrito/'+empresa+'.json?print=pretty';

                     if (empresa==124) {
                        
                         url_mensual='https://auth.foodservice.com.ar/?type=comedor_reporte&empresa='+empresa+'&desde='+desde+'&hasta='+hasta+'&access_token=1234567';
                      }

                      if (empresa==74||empresa==82||empresa=='0003'||empresa==17||empresa=="0007"||empresa==66||empresa==121||empresa==118||empresa==53||empresa==50||empresa==90||empresa==415||empresa==129) {

                        url_mensual='https://auth.foodservice.com.ar/?type=comedor_reporte&empresa='+empresa+'&desde='+desde+'&hasta='+hasta+'&access_token=1234567';
                      }

                       if (empresa==419) {

                        url_mensual='https://auth.foodservice.com.ar/?type=comedor_reporte_imac&empresa='+empresa+'&desde='+desde+'&hasta='+hasta+'&access_token=1234567';
                      }

                       if (empresa==64) {

                        url_mensual='https://auth.foodservice.com.ar/?type=comedor_reporte_iraola&empresa='+empresa+'&desde='+desde+'&hasta='+hasta+'&access_token=1234567';
                      }

                       if (empresa==107) {

                        url_mensual='https://auth.foodservice.com.ar/?type=comedor_reporte_sw&empresa='+empresa+'&desde='+desde+'&hasta='+hasta+'&access_token=1234567';
                      }

                      if (empresa==61) {

                        url_mensual='https://auth.foodservice.com.ar/?type=comedor_reporte&empresa='+empresa+'&desde='+desde+'&hasta='+hasta+'&access_token=1234567';
                      }

                       if (empresa==139) {

                        url_mensual='https://auth.foodservice.com.ar/?type=comedor_reporte&empresa='+empresa+'&desde='+desde+'&hasta='+hasta+'&access_token=1234567';
                      }

                      

                    var url_normal = url_mensual;

                             if (empresa==30000||empresa==77) {
  
                                    $("#consumos_tabla").html('<table id="consumos" class="table"></table>');
                                    $('#comensales').hide();
                                    $('#consumos').show();


                                                    var fechas = [];

                                       desde = $("#desde").val();
                                       hasta = $("#hasta").val();
                                       var operacion = $("#operacion").val();

                                       var inicio = desde;
                                       var fin = hasta;
                                       var dateOffset = (24*60*60*1000) * 1; //1 days

                                       var fechaInicio  = new Date(inicio);
                                       var fechaFin     = new Date(fin);

                                       fechaInicio.setTime(fechaInicio.getTime());
                                       fechaFin.setTime(fechaFin.getTime());

                                        while(fechaFin.getTime() >= fechaInicio.getTime()){
                                        fechaInicio.setDate(fechaInicio.getDate() + 1);

                                        fechas.push(("0" + (fechaInicio.getDate())).slice(-2)  + '-' + ("0" + (fechaInicio.getMonth() + 1)).slice(-2) + '-' + fechaInicio.getFullYear());
                                        }

                                           

        

                             if (empresa==30000) {
                                url_mensual='https://auth.foodservice.com.ar/?type=consumos_salida&desde='+desde+'&hasta='+hasta+'&op=381&access_token=1234567';
                              }

                               if (empresa==77) {
                                url_mensual='https://auth.foodservice.com.ar/?type=consumos_salida&desde='+desde+'&hasta='+hasta+'&op=13&access_token=1234567';
                              }
                  


                                    fechaInicio = new Date(desde).getTime();
                                    fechaFin    = new Date(hasta).getTime();

                                    var diff = fechaFin - fechaInicio;

                                    var tiempo = diff/(1000*60*60*24)*2;



                                    var timeleft = tiempo;

                                   
                                    
                                  

                                  

                                    console.log("esperando")

                                    $.ajax({
                                      url : url_mensual,
                                      type:'GET',
                                     success : function(json) {
                                                
                                                console.log(json)

                                               
                                                

                                                var columna = []; 
                                                columna.push({"title":"ID"})
                                                columna.push({"title":"NOMBRE"})
                                                columna.push({"title":"IDENTIFICADOR"})
                                                columna.push({"title":"EMPRESA"})
                                                for (var f = 0; f < fechas.length; f++) {
                                                    columna.push({"title":fechas[f]})
                                                   
                                                }
                                                    var tabla = $('#consumos').DataTable({

                                                                columns: columna,



                                                            "language": {
                                                                "decimal": ",",
                                                                "thousands": ".",
                                                                "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                                "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                                "infoPostFix": "",
                                                                "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                                                                "loadingRecords": "Cargando...",
                                                                "lengthMenu": "Mostrar _MENU_ registros",
                                                                "paginate": {
                                                                    "first": "Primero",
                                                                    "last": "Ultimo",
                                                                    "next": "Siguiente",
                                                                    "previous": "Anterior"
                                                                },
                                                                "processing": "Procesando...",
                                                                "search": "Buscar:",
                                                                "searchPlaceholder": "Termino de busqueda",
                                                                "zeroRecords": "No se encontraron resultados",
                                                                "emptyTable": "NO SE REGISTRAN CONSUMOS PARA ESTA OPERACIÒN",
                                                                "aria": {
                                                                    "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                                                    "sortDescending": ": Activar para ordenar la columna de manera descendente"
                                                                },
                                                            },
                                                            "scrollX": false, 
                                                            "search": {regex:true},
                                                          
                                                            dom: 'Bfrtip',
                                                            responsive: false,
                                                            nowrap: false,
                                                            buttons: [
                                                                'copy',
                                                                'excel',
                                                                'csv',
                                                                'pdf'
                                                            ]
                                                        });

                                            
                                           

                                          

                                                           var contador_pedidos = 0;
                                                           var pedidos_cat = [];

                                                            $.each(JSON.parse(json),function(clave,valor){
                                                               
                                                                var fechas = [];
                                                                fechas.push( valor.id,valor.nombre,valor.identificador,valor.empresa)
                                                                $.each(valor['fechas'],function(key,value){

                                                                    fechas.push(value.cantidad)
                                                                    pedidos_cat.push(value.cantidad)

                                                                });

                                                                

                                                               
                                                                   
                                                                 tabla.row.add(fechas).draw();
                                                                
                                                                  contador_pedidos = pedidos_cat.reduce((a, b) => a + b, 0);

                                                                
                                                            });

                                                            $("#mensaje").html("")

                                                            

                                            
                                        }
                                     
                                    });






                             }else{

                                if (empresa!="17") {$('.retiro').html("FECHA DE RETIRO");}
                    //abre tabla

                                var columnas =  [
                                { data: 'fecha' },
                                { data: 'fecha_retiro' },
                                { data: 'momento' },
                                { data: 'legajo' },
                                { data: 'usuario_nombre'},
                                { data: 'sucursal' },
                                { data: 'nombre' },
                                { data: 'postre' },
                                { data: 'status' },
                            ];
                                if (empresa=="0003") {
                                    columnas =  [
                                { data: 'fecha' },
                                { data: 'retiro' },
                                { data: 'turno' },
                                { data: 'legajo' },
                                { data: 'usuario_nombre'},
                                { data: 'sucursal' },
                                { data: 'nombre' },
                                { data: 'postre' },
                                { data: 'status' },
                            ];
                                }


                                if (empresa=="139") {
                                    columnas =  [
                                { data: 'fecha' },
                                { data: 'retiro' },
                                { data: 'turno' },
                                { data: 'legajo' },
                                { data: 'usuario_nombre'},
                                { data: 'sucursal' },
                                { data: 'nombre' },
                                { data: 'condicion' },
                                { data: 'status' },
                            ];
                                }


 if (empresa=="107") {

    $('.email').html("EMAIL");
                            columnas = [
    { data: 'fecha' },
    { 
        data: 'fecha_retiro',
        render: function(data, type, row) {
            if (data) {
                return data.split(' ')[1]; // Devuelve solo la hora
            }
            return data; // Devuelve el valor original si no se puede formatear
        }
    },
    { data: 'momento' },
    { data: 'legajo' },
    { data: 'usuario_nombre'},
    { data: 'sucursal' },
    { data: 'nombre' },
    { data: 'email' },
    { data: 'status' },
];
                                }
                   var table =  $('#comensales').DataTable({
                            processing: true,
                            ajax: url_normal,
                            columns:columnas,
                             "dom": '<"dt-buttons"Bf><"clear">lirtp',
                            "paging": true,
                            "autoWidth": true,
                             "scrollX": true,
                            "search": {regex: true},
                               "language": {
                                       
                                        "paginate": {
                                            "first": "Primero",
                                            "last": "ùltimo",
                                            "next": "Siguiente",
                                            "previous": "Anterior"
                                        },
                                        "processing": "Procesando datos, espere uno instante...",
                                        "search": "Buscar:",
                                        "searchPlaceholder": "Tèrmino de bùsqueda",
                                        "zeroRecords": "No se encontraron resultados",
                                        "emptyTable": "Ningùn dato disponible en esta tabla",
                                        "aria": {
                                            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                            "sortDescending": ": Activar para ordenar la columna de manera descendente"
                                        },
                                    },
                                      "buttons": [
                                      
                                      'copyHtml5',
                                      'csvHtml5',
                                      'excelHtml5',
                                      'pdfHtml5',
                                      'print'] ,

                                       initComplete: function () {

                                        console.log("finalizado")

                                            this.api()
                                                .columns()
                                                .every(function () {
                                                    var column = this;
                                                    var select = $('<select><option value=""></option></select>')
                                                        .appendTo($(column.footer()).empty())
                                                        .on('change', function () {
                                                            var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                 
                                                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                                                        });
                                 
                                                    column
                                                        .data()
                                                        .unique()
                                                        .sort()
                                                        .each(function (d, j) {
                                                            select.append('<option value="' + d + '">' + d + '</option>');
                                                        });
                                                });
           
                                        },


                            });

                    // cierra tabla
                   setTimeout(function(){
                    console.log(table.context[0].aoData.length)
                    if (table.context[0].aoData.length==0) {
                        $("#comensales_processing").hide()
                    }
                   },3000)
                   
                

                }
     
                    
             
      
 } );

     
        


    }

};


</script>

<style type="text/css">
    
div.dataTables_wrapper div.dataTables_processing {
    background-color: #a6bba5;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 434px;
    margin-left: %;
    margin-top: 19px;
    text-align: center;
    padding: 7px;
    z-index: 999999;
}

select.form-control, select, .email-compose-fields .select2-container--default select.select2-selection--multiple, .select2-container--default select.select2-selection--single, .select2-container--default .select2-selection--single select.select2-search__field, select.typeahead, select.tt-query, select.tt-hint {
    padding: 0 0.75rem;
    border: 1px solid #878787;
    border-radius: 0px;
    outline: none;
    color: #0c0808;
}

#consumos{display: none;}
</style>